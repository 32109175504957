import { BaseService, Service } from "/@/cool";
@Service("multilingual")
class Multilingual extends BaseService {
	all(data: {}) {
		return this.request({
			url: "/all",
			method: "POST",
			data
		});
	}
	listLanguage(data: {}) {
		return this.request({
			url: "/listLanguage",
			method: "POST",
			data
		});
	}
	uploadJson(data: {}) {
		return this.request({
			url: "/uploadJson",
			method: "POST",
			data
		});
	}
	getLanguage(data: {}) {
		return this.request({
			url: "/getLanguage",
			method: "GET",
			data
		});
	}
	delLanguage(data: {}) {
		return this.request({
			url: "/delLanguage",
			method: "POST",
			data
		});
	}
	addLanguage(data: {}) {
		return this.request({
			url: "/addLanguage",
			method: "POST",
			data
		});
	}
	saveLanguage(data: {}) {
		return this.request({
			url: "/saveLanguage",
			method: "POST",
			data
		});
	}
	languageSelectBy(data: {}) {
		return this.request({
			url: "/languageSelectBy",
			method: "GET",
			data
		});
	}
	getLanguageKey(data: {}) {
		return this.request({
			url: "/getLanguageKey",
			method: "POST",
			data
		});
	}
	addLanguageKey(data: {}) {
		return this.request({
			url: "/addLanguageKey",
			method: "POST",
			data
		});
	}
	saveLanguageKey(data: {}) {
		return this.request({
			url: "/saveLanguageKey",
			method: "POST",
			data
		});
	}
	delLanguageKey(data: {}) {
		return this.request({
			url: "/delLanguageKey",
			method: "POST",
			data
		});
	}
	listLanguageKey(data: {}) {
		return this.request({
			url: "/listLanguageKey",
			method: "POST",
			data
		});
	}
	importLanguageKey(data: {}) {
		return this.request({
			url: "/importLanguageKey",
			method: "POST",
			data
		});
	}
}
export default Multilingual;
