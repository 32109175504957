import { defineStore } from "pinia";
import { ref } from "vue";
import { storage } from "/@/cool/utils";
import { isDev, config } from "/@/cool";

interface Oem {
	[key: string]: any;
}

// 本地缓存
const data = storage.info();

export const useOemStore = defineStore("oem", function () {
	// oem信息
	const info = ref<Oem | null>(data.oem);

	// 设置oem信息
	function set(value: any) {
		info.value = value;
		storage.set("oem", value);
	}

	// 清除oem
	function clear() {
		storage.remove("oem");
		info.value = null;
	}

	// 获取oem信息
	function get() {
		return info.value;
	}

	function getValue(key: string) {
		return info.value?.[key] ?? "";
	}

	function logo() {
		return getValue("logo");
	}

	function brandName() {
		return getValue("brand_name");
	}

	function appTitle() {
		return getValue("photo_title");
	}

	function language() {
		return getValue("language");
	}

	function hasWechatLogin() {
		return getValue("wechat_login");
	}

	function wechatLoginUrl() {
		return getValue("wechat_login_url");
	}

	function shortLogo() {
		return getValue("short_logo");
	}

	function favicon() {
		return getValue("favicon");
	}

	function apiHost() {
		if (isDev) {
			return config.baseUrl;
		}

		return getValue("api_host");
	}

	function defaultPage() {
		return getValue("default_page");
	}

	function dataUrl() {
		return getValue("data_url");
	}

	function website() {
		return getValue("website_url");
	}

	function printLogo() {
		return getValue("print_logo");
	}

	function showPrintTip() {
		return getValue("show_print_tip");
	}

	function webwechat() {
		return getValue("webwechat");
	}

	function domain() {
		return getValue("domain");
	}

	function appid() {
		return getValue("wechat_login_appid");
	}

	function containExcludeMenu(menuId: number) {
		return (getValue("exclude_menus") || []).indexOf(menuId) != -1;
	}

	function getDepShopIds() {
		return getValue("dep_shop") || [];
	}

	function getDepDealerIds() {
		return getValue("dep_dealer") || [];
	}

	function getShopId() {
		return getValue("id") || [];
	}

	function isDepShop(shopId: number) {
		return getDepShopIds().indexOf(shopId) != -1;
	}

	function isDepDealer(dealerId: number) {
		return getDepDealerIds().indexOf(dealerId) != -1;
	}

	return {
		info,
		get,
		set,
		clear,
		getValue,
		getShopId,
		logo,
		brandName,
		appTitle,
		language,
		hasWechatLogin,
		shortLogo,
		favicon,
		apiHost,
		wechatLoginUrl,
		defaultPage,
		dataUrl,
		website,
		printLogo,
		showPrintTip,
		webwechat,
		domain,
		appid,
		containExcludeMenu,
		getDepShopIds,
		getDepDealerIds,
		isDepShop,
		isDepDealer
	};
});
